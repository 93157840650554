const Work = () => {
    return (
        <div className='main-container defaultpage'>


            <div className='border-container'>
                <h1>Work Page</h1>
            </div>
        </div>
    )
};


export default Work